@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Archivo";
  src: url("../public/fonts/archivo/static/Archivo-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: url("../public/fonts/archivo/static/Archivo-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: url("../public/fonts/archivo/static/Archivo-Italic.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
.roboto-font {
  font-family: "Roboto", sans-serif;
}
.archivo-font {
  font-family: "Archivo", sans-serif;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.error-message {
  @apply chat-text-red-100 chat-text-sm chat-mt-2 chat-text-left;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  width: 350px;
}
